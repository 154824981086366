* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logout {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background: #ee3a3a;
  border-radius: 10px;
  cursor: pointer;
}

.logout p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  cursor: pointer;
}

/* popup logout */

#popup-logout, #popup-recore {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 200;
  display: none;
  justify-content: center;
  align-items: center;
  animation-fill-mode: forwards;
}

.img-logout, .img-recore {
  max-width: 230px;
  height: auto;
}

.detail-logout, .detail-recore {
  background: #FFFFFF;
  border-radius: 20px;
  width: 480px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.image-logout, .image-recore {
  display: flex;
  justify-content: center;
}

.con-btn-logout, .con-btn-recore {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
}

.desc-logout, .desc-recore {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #797979;
  text-align: center;
}

.btn-batal-logout, .btn-batal-recore {
  background: #D8F0FF;
  border-radius: 10px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #2A93D5;
  width: 100%;
  cursor: pointer;
}

.btn-batal-logout:hover, .btn-batal-recore:hover {
  background: #2A93D5;
  color: #d8f0ff;
  transition: 0.25s ease-in-out;
}

.btn-keluar, .btn-recore {
  background: #FFC6C6;
  border-radius: 10px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 22px; */
  color: #FF3F3F;
  width: 100%;
  padding: 14px 0;
  cursor: pointer;
}

.btn-keluar:hover, .btn-recore:hover {
  background: #FF3F3F;
  color: #d8f0ff;
  transition: 0.25s ease-in-out;
}

/* end popup logout */

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.error {
  color: red;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

header {
  background-image: url(/src/Assets/img-header-apkb2.jpg);
  width: 100%;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--primer);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  gap: 30px;
}

header ul {
  display: flex;
  gap: 20px;
  list-style: none;
  background-color: #ffffff5d;
  padding: 10px 40px;
  border-radius: 30px;
}

header ul li a {
  text-decoration: none;
  color: var(--primer);
  font-weight: 600;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

header ul li a:hover {
  color: var(--aksen1);
}

/* active */

header ul li.active a {
  color: #fff;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

footer {
  background-image: url(/src/Assets/img-header-apkb2.jpg);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.copyright {
  background: #0e2954;
  width: 100%;
  padding: 20px;
  color: #fff;
  text-align: center;
}

/* popup success */

#popup-success,
#popup-success-addCode,
#popup-success-ChangesPass, #popup-success-pegawai {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 200;
  display: none;
  justify-content: center;
  align-items: center;
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0.25);
}

.img-success {
  max-width: 230px;
  height: auto;
}

.detail-success,
.detail-success-addCode,
#detail-success-ChangesPass {
  background: #ffffff;
  border-radius: 20px;
  width: 480px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.image-success {
  display: flex;
  justify-content: center;
}

.desc-success {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 22px; */
  color: #797979;
  text-align: center;
}

.btn-success,
.btn-out {
  background: #d8f0ff;
  border-radius: 10px;
  border: none;
  margin-top: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 22px; */
  color: #2a93d5;
  width: 100%;
  padding: 14px 0;
  cursor: pointer;
}

.btn-success:hover,
.btn-out:hover {
  background: #2a93d5;
  color: #fff;
  transition: 0.25s ease-in-out;
}

/* end popup success */

/* popup Failed */

#popup-Failed, #popup-Failed-PLH, #popup-Failed-SameIdent, #Relog, #popup-FailedHeadOfficer {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 200;
  display: none;
  justify-content: center;
  align-items: center;
  animation-fill-mode: forwards;
  background: rgba(0, 0, 0, 0.25);
}

.img-Failed, .img-Failed-PLH, .img-Failed-SameIdent, .img-FailedHeadOfficer {
  max-width: 230px;
  height: auto;
}

.detail-Failed, .detail-Failed-PLH, .detail-Failed-SameIdent, .detail-Relog, .detail-FailedHeadOfficer {
  background: #ffffff;
  border-radius: 20px;
  width: 480px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.image-Failed, .image-Failed-PLH, .image-Failed-SameIdent, .image-Relog, .image-FailedHeadOfficer {
  display: flex;
  justify-content: center;
}

.desc-Failed, .desc-Failed-PLH, .desc-Failed-SameIdent, .desc-Relog, .desc-FailedHeadOfficer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 22px; */
  color: #797979;
  text-align: center;
}

.btn-Failed, .btn-Failed-PLH, .btn-Relog, .btn-FailedHeadOfficer {
  background: #ffb0b0;
  border-radius: 10px;
  border: none;
  margin-top: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 22px; */
  color: #ff0e0e;
  width: 100%;
  padding: 14px 0;
  cursor: pointer;
}

.btn-Failed2 {
  background: #ffb0b0;
  border-radius: 10px;
  border: none;
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 22px; */
  color: #ff0e0e;
  width: 100%;
  padding: 14px 0;
  cursor: pointer;
}

.btn-Failed:hover,
.btn-Failed-PLH:hover,
.btn-Failed2:hover, .btn-Relog:hover, .btn-FailedHeadOfficer:hover {
  background: #ee3a3a;
  color: #fff;
  transition: 0.25s ease-in-out;
}

/* end popup Failed */

/* popup loading */
.popup-loading {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 200;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
}

.body-loading {
  /* background: var(--bg); */
  /* color: var(--fg); */
  font: 1em/1.5 sans-serif;
  /* height: 20vh; */
  display: none;
  place-items: center;
}

.pl {
  display: block;
  width: 6.25em;
  height: 6.25em;
}

.pl__ring,
.pl__ball {
  animation: ring 2s ease-out infinite;
}

.pl__ball {
  animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
  :root {
    color: #1d77af;
    color: #1d77af;
  }
}

/* Animation */
@keyframes ring {
  from {
    stroke-dasharray: 0 257 0 0 1 0 0 258;
  }

  25% {
    stroke-dasharray: 0 0 0 0 257 0 258 0;
  }

  50%,
  to {
    stroke-dasharray: 0 0 0 0 0 515 0 0;
  }
}

@keyframes ball {

  from,
  50% {
    animation-timing-function: ease-in;
    stroke-dashoffset: 1;
  }

  64% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -109;
  }

  78% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -145;
  }

  92% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -157;
  }

  57%,
  71%,
  85%,
  99%,
  to {
    animation-timing-function: ease-out;
    stroke-dashoffset: -163;
  }
}

.body-loadingDetail {
  background: #ffffff;
  /* height: 200px; */
  width: 220px;
  height: 70px;
  border-radius: 10px;
  display: none;
  place-items: center;
}

.body-loadingDetail p {
  color: var(--color-aksen);
  font-weight: 500;
  font-family: poppins;
  font-size: 16px;
}

@keyframes load {
  0% {
    opacity: 0.08;
    /*         font-size: 10px; */
    /* 				font-weight: 400; */
    filter: blur(5px);
    letter-spacing: 3px;
  }

  100% {
    /*         opacity: 1; */
    /*         font-size: 12px; */
    /* 				font-weight:600; */
    /* 				filter: blur(0); */
  }
}

.animate-loadingDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height:100%; */
  margin: auto;
  /* 	width: 350px; */
  /* 	font-size:26px; */
  font-family: Helvetica, sans-serif, Arial;
  animation: load 1.2s infinite 0s ease-in-out;
  animation-direction: alternate;
  text-shadow: 0 0 1px white;
}

/* end */
/* end popup loading */

/* popup Delete */
#popup-Delete {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 200;
  display: none;
  justify-content: center;
  align-items: center;
  animation-fill-mode: forwards;
  background: rgba(0, 0, 0, 0.25);
}

.img-Delete {
  max-width: 230px;
  height: auto;
}

.detail-Delete {
  background: #FFFFFF;
  border-radius: 20px;
  width: 480px;
  display: none;
  flex-direction: column;
  padding: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.image-Delete {
  display: flex;
  justify-content: center;
}

.con-btn-Delete {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
}

.desc-Delete {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 22px; */
  color: #797979;
  text-align: center;
}

.btn-batal {
  background: #D8F0FF;
  border-radius: 10px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 22px; */
  color: #2A93D5;
  width: 100%;
  cursor: pointer;
}

.btn-batal:hover {
  background: #2A93D5;
  color: #fff;
  transition: 0.25s ease-in-out;
}

.btn-delete {
  background: #FFC6C6;
  border-radius: 10px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 22px; */
  color: #FF3F3F;
  width: 100%;
  padding: 14px 0;
  cursor: pointer;
}

.btn-delete:hover {
  background: #FF3F3F;
  color: #fff;
  transition: 0.25s ease-in-out;
}

/* end popup Delete */