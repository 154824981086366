@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #f5f5f5;
    --secondary-color: #e0e0e0;
    --tertiary-color: #bdbdbd;
    --dark-color: #212121;
    --light-color: #ffffff;
    --success-color: #4caf50;
    --danger-color: #f44336;
    --warning-color: #ff9800;
    --info-color: #2196f3;
    --dark-secondary-color: #757575;
    --dark-tertiary-color: #616161;
    --dark-light-color: #9e9e9e;
    --dark-success-color: #388e3c;
    --dark-danger-color: #d32f2f;
    --dark-warning-color: #f57c00;
    --dark-info-color: #1976d2;

    --primer: #fff;
    --sekunder: #6F8FFF;
    --aksen1: #BED7FF;
    --aksen2: #40649F;
}