@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.table-container {
    max-width: 90vw;
    overflow-x: auto;
    margin: 0 auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    table-layout: fixed;
}

th,
td {
    width: 300px;
    /* word-wrap: break-word; */
    border: 2px solid #cbcbcb;
    text-align: center;
    padding: 5px;
}

th {
    color: #40649F;
    font-family: "Poppins", "sans-serif";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

td {
    color: #4B4B4B;
    font-family: "Poppins", "sans-serif";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* Contoh CSS untuk mengatur tampilan sel aktif */
tr:nth-child(1):hover {
    background: #fff;
    cursor: text;
}

tr:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}

/* custom scroll bar */
/* width */
::-webkit-scrollbar {
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.header-table {
    display: flex;
    margin-top: 20px;
    width: 65%;
    gap: 20px;
}

.header-table .search {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid #C4C4C4;
    background: #FFF;
    width: max-content;
}

.header-table .search input {
    border: none;
    outline: none;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #000;
}

.header-table .search input::placeholder {
    color: #797979;
}

.header-table .search .icon-search {
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #40649F;
}

.header-table .exel{
    border-radius: 10px;
    background: #63C86D;
    color: #FFF;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.action-db {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.action-db .edit {
    color: #707813;
    border-radius: 10px;
    background: #FFED4C;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.action-db .print {
    color: #fff;
    border-radius: 10px;
    background: #63C86D;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.action-db .delete {
    color: #fff;
    border-radius: 10px;
    background: #FF6F6F;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.action-db .preview {
    color: #fff;
    border-radius: 10px;
    background: var(--info-color);
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.action-db .edit:hover {
    background: #fbe62b;
}

.action-db .print:hover {
    background: #1ca12a;
}

.action-db .delete:hover {
    background: #fd4444;
}

.action-db .preview:hover {
    background: var(--aksen2);
}