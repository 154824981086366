@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #f5f5f5;
    --secondary-color: #e0e0e0;
    --tertiary-color: #bdbdbd;
    --dark-color: #212121;
    --light-color: #ffffff;
    --success-color: #4caf50;
    --danger-color: #f44336;
    --warning-color: #ff9800;
    --info-color: #2196f3;
    --dark-secondary-color: #757575;
    --dark-tertiary-color: #616161;
    --dark-light-color: #9e9e9e;
    --dark-success-color: #388e3c;
    --dark-danger-color: #d32f2f;
    --dark-warning-color: #f57c00;
    --dark-info-color: #1976d2;

    --primer: #fff;
    --sekunder: #6F8FFF;
    --aksen1: #BED7FF;
    --aksen2: #40649F;
}

.container {
    max-width: 1040px;
    margin: 50px auto;
}

.title-form {
    border-radius: 20px;
    background: #BED7FF;
    padding: 15px;
}

.title-form h1 {
    text-align: center;
    color: #40649F;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.body-form {
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #C4C4C4;
    background: #FFF;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.body-form .form-input {
    width: 100%;
    position: relative;
}

.body-form .form-input p {
    color: #4B4B4B;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}

.body-form .form-input input {
    width: 100%;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    padding: 10px 15px;
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.body-form .form-input .css-1fdsijx-ValueContainer{
    width: 100%;
    border: 1px solid #C4C4C4;
    color: #797979;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 6px 15px;
}

.button-tangging {
    gap: 10px;
    width: 100%;
    display: flex;
    height: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.button-tangging button {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    transition: .2s ease-in-out all;
    color: #FFF;
    font-family: "popins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button-tangging button#cancel {
    background: #FF6F6F;
    color: #FFF;
    border: 1px solid #C4C4C4;
}

.button-tangging button#cancel:hover {
    background: #f44336;
}

.button-tangging button#continue {
    background: #6F8FFF;
    border: 1px solid #C4C4C4;
}

.button-tangging button#continue:hover {
    background: #1976d2;
    color: #fff;
}

textarea{
    width: 100%;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    padding: 10px 15px;
    color: #797979;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.button-form {
    gap: 10px;
    width: 100%;
    display: flex;
    height: 50px;
}

.button-form button {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    transition: .2s ease-in-out all;
    color: #FFF;
    font-family: "popins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button-form button#clear {
    background: #FF6F6F;
    color: #FFF;
    border: 1px solid #C4C4C4;
}

.button-form button#clear:hover {
    background: #f44336;
}

.button-form button#add {
    background: #6F8FFF;
    border: 1px solid #C4C4C4;
}

.button-form button#add:hover {
    background: #1976d2;
    color: #fff;
}

.button-form button#save{
    background: #63C86D;
    color: #fff;
    border: 1px solid #C4C4C4;
}

.button-form button#save:hover{
    background: #388e3c;
    color: #fff;
}

.hiddenPlh {
    display: none;
  }
  