@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.body-login{
    background-image: url(../Assets/img-header-apkb.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}

.logo-beceku-head{
    width: 450px;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.box-login {
    border-radius: 20px;
    border: 1px solid #C4C4C4;
    /* background glass */
    background: #ffffffca;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 90%;
    max-width: 687px;
    padding: 20px;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 500px) {
    .box-login {
        width: 90%;
    }
}

.box-login h1 {
    color: #4B4B4B;
    font-family: "Poppins", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 14px;
}

.box-login .text-info {
    color: #4B4B4B;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-bottom: 40px;
}

.box-login form p {
    color: #4B4B4B;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 10px;
}

.box-login form input {
    width: 100%;
    border-radius: 10px;
    padding: 10px 15px;
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: #ffffff93;
}

.box-login form input:focus {
    outline: none;
}

.box-login form .btnLogin {
    width: 100%;
    border: none;
    padding: 10px 15px;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 10px;
    background: #6F8FFF;
    cursor: pointer;
    margin-top: 50px;
}

.box-login form .btnLogin:hover {
    background: #40649F;
}

.con-form-username input, .con-form-password input {
    border: none;
}

.con-form-username, .con-form-password {
    display: flex;
    background: #FFF;
    align-items: center;
    border-radius: 10px;
    padding: 0 20px;
}

.con-form-username input, .con-form-password input {
    border: none;
}

.btn-mata {
    background: transparent;
    border: none;
    color: var(--color-primer);
    margin: 0;
    padding: 0;
    cursor: pointer;
}